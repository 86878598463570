<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="600px">
    <v-card>
      <v-card-title class="headline">Edit Day</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="day-form">
          <v-text-field
            label="Title/Name"
            v-model="fields.text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-textarea
            label="Description of Day"
            v-model="fields.description"
            outlined
            background-color="white"
          ></v-textarea>
          <v-autocomplete
            label="Courses"
            v-model="selectedCourses"
            :items="courses"
            item-text="name"
            item-value="id"
            multiple
            outlined
            chips
            background-color="white"
          ></v-autocomplete>
          <v-sheet outlined class="pa-4 mb-8" v-if="fields.courses.length > 0">
            <v-row>
              <v-col
                cols="6"
                v-for="(course, index) in fields.courses"
                v-bind:key="course.id"
              >
                <h4 class="mb-3">Details for {{ getCourseName(course.id) }}</h4>
                <v-text-field
                  label="Tee Time"
                  v-model="fields.courses[index].tee_time"
                  outlined
                  dense
                  hide-details
                  class="mb-2"
                  background-color="white"
                ></v-text-field>
                <v-textarea
                  label="Notes"
                  v-model="fields.courses[index].notes"
                  rows="3"
                  outlined
                  dense
                  hide-details
                  background-color="white"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-sheet>
          <v-textarea
            label="After Golf"
            v-model="fields.after_golf"
            outlined
            background-color="white"
          ></v-textarea>
          <v-autocomplete
            label="Events"
            v-model="selectedEvents"
            :items="events"
            item-text="name"
            item-value="id"
            multiple
            outlined
            chips
            background-color="white"
          ></v-autocomplete>
          <v-autocomplete
            label="Accommodation"
            v-model="selectedAccommodations"
            :items="accommodations"
            item-text="name"
            item-value="id"
            multiple
            outlined
            chips
            background-color="white"
          ></v-autocomplete>
          <v-text-field
            label="Room Type"
            v-model="fields.room_type"
            outlined
            background-color="white"
          ></v-text-field>
          <v-autocomplete
            label="Sightseeing Suggestions"
            v-model="selectedSightseeings"
            :items="sightseeings"
            item-text="title"
            item-value="id"
            multiple
            outlined
            chips
            background-color="white"
          ></v-autocomplete>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="day-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      fields: {
        text: "",
        description: "",
        courses: [],
        after_golf: "",
        events: [],
        accommodations: [],
        room_type: "",
        sightseeings: [],
      },
      day: {},
      errors: {},
    };
  },

  computed: {
    courses() {
      return this.$store.state.drum.admin["courses"];
    },

    events() {
      return this.$store.state.drum.admin["events"];
    },

    accommodations() {
      return this.$store.state.drum.admin["accommodations"];
    },

    sightseeings() {
      return this.$store.state.drum.admin["sightseeings"];
    },

    selectedCourses: {
      get() {
        return this.fields.courses.map((c) => {
          return c["id"];
        });
      },

      set(courses) {
        let setCourses = this.fields.courses;

        courses.forEach((id) => {
          let found = false;
          setCourses.forEach((c) => {
            if (c.id === id) {
              found = true;
            }
          });

          if (found === false) {
            setCourses.push({
              id,
              tee_time: "",
              notes: "",
            });
          }
        });

        this.fields.courses = setCourses.filter((c) => {
          return courses.includes(c.id);
        });
      },
    },

    selectedAccommodations: {
      get() {
        return this.fields.accommodations.map((a) => {
          return a;
        });
      },

      set(accommodations) {
        this.fields.accommodations = accommodations.filter((a) => {
          return accommodations.includes(a);
        });
      },
    },

    selectedEvents: {
      get() {
        return this.fields.events.map((e) => {
          return e;
        });
      },

      set(events) {
        this.fields.events = events.filter((e) => {
          return events.includes(e);
        });
      },
    },

    selectedSightseeings: {
      get() {
        return this.fields.sightseeings.map((s) => {
          return s;
        });
      },

      set(sightseeings) {
        this.fields.sightseeings = sightseeings.filter((s) => {
          return sightseeings.includes(s);
        });
      },
    },
  },

  methods: {
    openForm: function (day) {
      this.fields.text = day.text;
      this.fields.description = day.description;
      this.fields.after_golf = day.after_golf;
      this.fields.events = day.events;
      this.fields.room_type = day.room_type;
      this.fields.sightseeings = day.sightseeings;
      this.day = day;

      if (day.courses.length > 0) {
        let courses = [];
        day.courses.forEach((course) => {
          courses.push({
            id: course.id,
            tee_time: course.pivot.tee_time,
            notes: course.pivot.notes,
          });
        });
        this.fields.courses = courses;
      }

      if (day.accommodations.length > 0) {
        let accommodations = [];
        day.accommodations.forEach((accommodation) => {
          accommodations.push(accommodation.id);
        });
        this.fields.accommodations = accommodations;
      }

      if (day.events.length > 0) {
        let events = [];
        day.events.forEach((event) => {
          events.push(event.id);
        });
        this.fields.events = events;
      }

      if (day.sightseeings.length > 0) {
        let sightseeings = [];
        day.sightseeings.forEach((sightseeing) => {
          sightseeings.push(sightseeing.id);
        });
        this.fields.sightseeings = sightseeings;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.datePicker = null;
      this.fields = {
        text: null,
        description: "",
        courses: [],
        after_golf: "",
        events: [],
        accommodations: [],
        room_type: "",
        sightseeings: [],
      };
      this.day = {};
      this.errors = {};
    },

    saveForm: function () {
      const appId = this.$route.params.id;
      const tourId = this.$route.params.tourId;
      const itineraryId = this.$route.params.itineraryId;
      const dayId = this.day.id;
      this.loading = true;
      this.errors = {};

      this.$store
        .dispatch("drum/tours/saveItineraryDay", {
          appId,
          tourId,
          itineraryId,
          dayId,
          isEditing: true,
          fields: this.fields,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    getCourseName: function (id) {
      const courseIndex = this.courses.findIndex((c) => c.id === id);
      const course = this.courses[courseIndex];
      return course.name;
    },
  },
};
</script>
