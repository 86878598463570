<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h2>Itinerary</h2>
      </v-col>
      <v-col>
        <v-row align="center" justify="end" no-gutters>
          <v-btn
            small
            depressed
            color="green white--text"
            @click="createDay()"
            :loading="isDayCreating"
          >
            <v-icon left>mdi-plus</v-icon>Add New Day
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-switch
          label="Start Date Known?"
          v-model="startDateFields.start_date_known"
          color="green"
          class="mt-1 mb-4"
          inset
          :loading="startDateFieldsLoading"
        ></v-switch>
      </v-col>
      <v-col cols="3">
        <v-menu
          v-model="startDatePicker"
          v-if="startDateFields.start_date_known"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
          max-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Start Date"
              v-model="startDateFormatted"
              outlined
              dense
              :loading="startDateFieldsLoading"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDateFields.start_date"
            @input="startDatePicker = false"
            first-day-of-week="1"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-sheet elevation="2" outlined v-bind:style="{ position: 'relative' }">
      <v-overlay absolute z-index="2" v-if="loading">
        <v-progress-circular :size="45" indeterminate></v-progress-circular>
      </v-overlay>
      <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
      <v-expansion-panels multiple focusable v-if="days.length > 0">
        <draggable
          v-model="days"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
          v-bind:style="{
            width: '100%',
          }"
          handle=".handle"
        >
          <transition-group
            type="transition"
            :name="!drag ? 'flip-list' : null"
          >
            <v-expansion-panel v-for="day in days" :key="day.id">
              <v-expansion-panel-header>
                <v-row no-gutters align="center">
                  <v-col cols="4">
                    <v-icon small class="page__grab-icon mr-2 handle"
                      >mdi-arrow-all</v-icon
                    >
                    <strong>Day {{ day.dayNumber }}</strong>
                  </v-col>
                  <v-col cols="6">{{ getDate(day.dayNumber) }}</v-col>
                  <v-col cols="2" class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          x-small
                          depressed
                          color="blue lighten-4 blue--text"
                          v-on="on"
                          class="mr-2"
                          @click="$refs.dayForm.openForm(day)"
                        >
                          <v-icon x-small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          x-small
                          depressed
                          color="red lighten-4 red--text"
                          v-on="on"
                          class="mr-2"
                          @click="openDelete(day)"
                        >
                          <v-icon x-small>mdi-archive</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4">
                <div class="text-right">
                  <v-btn
                    color="info"
                    depressed
                    tile
                    @click="$refs.dayForm.openForm(day)"
                    ><v-icon left>mdi-pencil</v-icon>Edit</v-btn
                  >
                </div>
                <v-sheet outlined>
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <th>Title/Name</th>
                        <td>
                          <div v-if="day.text">
                            {{ day.text }}
                          </div>
                          <div v-else class="text--disabled">Null</div>
                        </td>
                      </tr>
                      <tr>
                        <th>Description</th>
                        <td>
                          <div v-if="day.description">
                            {{ day.description }}
                          </div>
                          <div v-else class="text--disabled">Null</div>
                        </td>
                      </tr>
                      <tr>
                        <th>Courses</th>
                        <td>
                          <div v-if="day.courses.length > 0" class="py-3">
                            <tr
                              v-for="course in day.courses"
                              v-bind:key="course.id"
                            >
                              <th class="text-left pr-4">{{ course.name }}</th>
                              <td>
                                <div>Tee Time: {{ course.pivot.tee_time }}</div>
                                <div>Notes: {{ course.pivot.notes }}</div>
                              </td>
                            </tr>
                          </div>

                          <div v-else class="text--disabled">Null</div>
                        </td>
                      </tr>
                      <tr>
                        <th>After Golf</th>
                        <td>
                          <div v-if="day.after_golf">{{ day.after_golf }}</div>
                          <div v-else class="text--disabled">Null</div>
                        </td>
                      </tr>
                      <tr>
                        <th>Events</th>
                        <td>
                          <div v-if="day.events.length > 0">
                            <ul>
                              <li
                                v-for="event in day.events"
                                v-bind:key="event.id"
                              >
                                {{ event.name }}
                              </li>
                            </ul>
                          </div>
                          <div v-else class="text--disabled">Null</div>
                        </td>
                      </tr>
                      <tr>
                        <th>Accommodation</th>
                        <td>
                          <div v-if="day.accommodations.length > 0">
                            <ul>
                              <li
                                v-for="accommodation in day.accommodations"
                                v-bind:key="accommodation.id"
                              >
                                {{ accommodation.name }}
                              </li>
                            </ul>
                          </div>
                          <div v-else class="text--disabled">Null</div>
                        </td>
                      </tr>
                      <tr>
                        <th>Room Type</th>
                        <td>
                          <div v-if="day.room_type">{{ day.room_type }}</div>
                          <div v-else class="text--disabled">Null</div>
                        </td>
                      </tr>
                      <tr>
                        <th>Sightseeing Suggestions</th>
                        <td>
                          <div v-if="day.sightseeings.length > 0">
                            <ul>
                              <li
                                v-for="sightseeing in day.sightseeings"
                                v-bind:key="sightseeing.id"
                              >
                                {{ sightseeing.title }}
                              </li>
                            </ul>
                          </div>
                          <div v-else class="text--disabled">Null</div>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-sheet>
                <div class="text-right">
                  <v-btn
                    color="info"
                    depressed
                    tile
                    @click="$refs.dayForm.openForm(day)"
                    ><v-icon left>mdi-pencil</v-icon>Edit</v-btn
                  >
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </transition-group>
        </draggable>
      </v-expansion-panels>
      <p class="mb-0 pa-5" v-else>There are currently no days for this Tour</p>
      <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
    </v-sheet>
    <v-row justify="end" no-gutters class="mt-5">
      <v-btn
        small
        depressed
        color="green white--text"
        @click="createDay()"
        :loading="isDayCreating"
      >
        <v-icon left>mdi-plus</v-icon>Add New Day
      </v-btn>
    </v-row>
    <DayForm ref="dayForm" />
    <v-dialog v-model="deleteDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete Day</v-card-title>
        <v-card-text>Are you sure you want to delete this day?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteDialog.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DayForm from "./components/DayForm";
import Draggable from "vuedraggable";

export default {
  components: {
    DayForm,
    Draggable,
  },

  props: {
    tour: {
      type: Object,
      required: true,
    },
    itinerary: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      startDatePicker: false,
      startDateFields: {
        start_date_known: null,
        start_date: null,
      },
      startDateFieldsLoading: false,
      isDayCreating: false,
      deleteDialog: {
        open: false,
        loading: false,
        day: {},
      },
      drag: false,
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      },
      pageLoaded: false,
    };
  },

  computed: {
    courses() {
      return this.$store.state.drum.admin["courses"];
    },
    events() {
      return this.$store.state.drum.admin["events"];
    },
    accommodations() {
      return this.$store.state.drum.admin["accommodations"];
    },
    sightseeings() {
      return this.$store.state.drum.admin["sightseeings"];
    },
    startDateFormatted() {
      return this.formatDate(this.startDateFields.start_date);
    },
    startDateKnown() {
      return this.startDateFields.start_date_known;
    },
    days: {
      get() {
        let days = this.itinerary.days;
        days.forEach((day, index, days) => {
          days[index]["datePicker"] = false;
          days[index]["dayNumber"] = index + 1;
        });

        return days;
      },
      set(days) {
        const displayOrder = days.map((d) => {
          return d.id;
        });
        const appId = this.$route.params.id;
        const tourId = this.$route.params.tourId;
        const itineraryId = this.$route.params.itineraryId;

        this.$store.commit("showHideLoader", true);
        this.loading = true;

        this.$store
          .dispatch("drum/tours/updateItineraryDayOrder", {
            appId,
            tourId,
            itineraryId,
            displayOrder,
          })
          .then(() => {
            this.$store.commit("showHideLoader", false);
            this.loading = false;
          })
          .catch(() => {
            this.$store.commit("showHideLoader", false);
            this.loading = false;
          });
      },
    },
  },

  created() {
    this.startDateFields.start_date_known = this.itinerary.start_date_known;
    this.startDateFields.start_date = this.itinerary.start_date;
    this.pageLoaded = true;
  },

  watch: {
    startDateKnown() {
      if (this.pageLoaded) {
        this.updateStartDate();
      }
    },

    startDateFormatted() {
      if (this.pageLoaded) {
        this.updateStartDate();
      }
    },
  },

  methods: {
    addNewDay: function () {
      const index = this.days.length + 1;
      this.days.push({
        dayNumber: index,
        datePicker: false,
        description: null,
        after_golf: null,
        events: [],
        accommodation: [],
        room_type: null,
        sightseeing: [],
      });
    },

    getDate: function (dayNumber) {
      if (this.itinerary.start_date) {
        if (dayNumber > 1) {
          let date = new Date(this.itinerary.start_date);
          date.setDate(date.getDate() + (dayNumber - 1));

          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();
          const dateString = `${year}-${month}-${day}`;

          return this.formatDateAsString(dateString);
        }

        return this.formatDateAsString(this.itinerary.start_date);
      }

      return null;
    },

    getCourseName: function (id) {
      const courseIndex = this.courses.findIndex((c) => c.id === id);
      const course = this.courses[courseIndex];
      return course.name;
    },

    updateStartDate: function () {
      if (this.pageLoaded === false) {
        return;
      }

      const appId = this.$route.params.id;
      const tourId = this.$route.params.tourId;
      const itineraryId = this.$route.params.itineraryId;
      this.startDateFieldsLoading = true;

      if (this.startDateFields.start_date_known === false) {
        this.startDateFields.start_date = null;
      }

      this.$store
        .dispatch("drum/tours/saveItinerary", {
          appId,
          tourId,
          itineraryId,
          isEditing: true,
          fields: this.startDateFields,
        })
        .then(() => {
          this.startDateFieldsLoading = false;
        })
        .catch(() => {
          this.startDateFieldsLoading = false;
          this.startDateFields.start_date_known =
            this.itinerary.start_date_known;
          this.startDateFields.start_date = this.itinerary.start_date;
        });
    },

    createDay: function () {
      const appId = this.$route.params.id;
      const tourId = this.$route.params.tourId;
      const itineraryId = this.$route.params.itineraryId;
      this.isDayCreating = true;
      this.loading = true;

      this.$store
        .dispatch("drum/tours/saveItineraryDay", {
          appId,
          tourId,
          itineraryId,
          fields: {},
        })
        .then(() => {
          this.isDayCreating = false;
          this.loading = false;
        })
        .catch(() => {
          this.isDayCreating = false;
          this.loading = false;
        });
    },

    openDelete: function (day) {
      this.deleteDialog.day = day;
      this.deleteDialog.open = true;
    },

    resetDelete: function () {
      this.deleteDialog.loading = false;
      this.deleteDialog.open = false;
      this.deleteDialog.day = {};
    },

    saveDelete: function () {
      const appId = this.$route.params.id;
      const tourId = this.$route.params.tourId;
      const itineraryId = this.$route.params.itineraryId;
      this.deleteDialog.loading = true;

      this.$store
        .dispatch("drum/tours/deleteItineraryDay", {
          appId,
          tourId,
          itineraryId,
          dayId: this.deleteDialog.day.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteDialog.loading = false;
        });
    },
  },
};
</script>
